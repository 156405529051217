<template>
  <div class="banner">
    <v-img :src="img" :height="height" />
    <v-container>
      <v-row justify="center">
        <v-col md="10" style="background-color: #fff;" class="pa-8">
          <h1 class="primary--text mb-4">Online Donations</h1>
          <p>
            <v-btn
              href="https://secure.givelively.org/donate/bridge-to-turkiye/btf-friends-of-kahev"
              target="_blank"
              elevation="0"
              class="font-weight-bold mr-4"
              color="secondary"
              >BTF DONATION</v-btn
            >

            <v-btn
              href="https://donate.tpfund.org/campaign/kahev/c310531"
              target="_blank"
              elevation="0"
              class="font-weight-bold"
              color="secondary"
              >TPF DONATION</v-btn
            >
          </p>

          <h1 class="primary--text mt-12 mb-4">
            KAHEV Disaster Fund Bank Accounts
          </h1>

          <h3>TL Account</h3>
          <ul>
            <li><strong>IBAN:</strong> TR21 0011 1000 0000 0086 3252 80</li>
            <li>
              <strong>Recipient Name:</strong> Kadın Hekimler Eğitime Destek
              Vakfı (KAHEV)
            </li>
            <li><strong>Description:</strong> Disaster Fund Donation</li>
            <li><strong>Bank:</strong> QNB Finansbank</li>
          </ul>

          <h3>Dollar ($) Account</h3>
          <ul>
            <li>
              <strong>Recipient Name:</strong> Kadın Hekimler Eğitime Destek
              Vakfı (KAHEV)
            </li>
            <li><strong>IBAN:</strong> TR39 0011 1000 0000 0094 7962 57</li>
            <li><strong>Bank:</strong> QNB Finansbank</li>
            <li><strong>Branch:</strong> Eskisehir</li>
            <li><strong>Branch Code:</strong> 260</li>
            <li><strong>Swift Code:</strong> FNNBTRISESK</li>
          </ul>

          <h3>Euro (€) Account</h3>
          <ul>
            <li>
              <strong>Recipient Name:</strong> Kadın Hekimler Eğitime Destek
              Vakfı (KAHEV)
            </li>
            <li><strong>IBAN:</strong> TR93 0011 1000 0000 0092 0432 01</li>
            <li><strong>Bank:</strong> QNB Finansbank</li>
            <li><strong>Branch:</strong> Eskisehir</li>
            <li><strong>Branch Code:</strong> 260</li>
            <li><strong>Swift Code:</strong> FNNBTRISESK</li>
          </ul>

          <h3>Sterlin/Pound (£) Account</h3>
          <ul>
            <li>
              <strong>Recipient Name:</strong> Kadın Hekimler Eğitime Destek
              Vakfı (KAHEV)
            </li>
            <li><strong>IBAN:</strong> TR54 0011 1000 0000 0120 0010 49</li>
            <li><strong>Bank:</strong> QNB Finansbank</li>
            <li><strong>Branch:</strong> Eskişehir</li>
            <li><strong>Branch Code:</strong> 260</li>
            <li><strong>Swift Code:</strong> FNNBTRISESK</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      img: require("@/assets/banner-donation.jpg")
    };
  },

  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return 300;
        case "md":
          return 600;
        case "lg":
          return 600;
        case "xl":
          return 600;
        default:
          return 300;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.banner {
  overflow: hidden;
  position: relative;

  .container {
    padding: 0;
  }

  @media #{map-get($display-breakpoints, "md-and-up")} {
    .container {
      position: relative;
      top: -80px;
    }
  }
}
</style>
